import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Home from './Pages/Home';
import RecordList from './Pages/RecordList';
import RecordDetail from './Pages/RecordDetail';
import UpLoadRecord from './Pages/UpLoadRecord';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route, } from "react-router-dom";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Home></Home>}></Route>
        <Route path='RecordList' element={<RecordList></RecordList>}></Route>
        <Route path='RecordDetail' element={<RecordDetail></RecordDetail>}></Route>
        <Route path='UpLoadRecord' element={<UpLoadRecord></UpLoadRecord>}></Route>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
